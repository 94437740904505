import './App.css';

function App() {
    const domain = /:\/\/([^\/]+)/.exec(window.location.href)[1];
    return (
        <div className="App">
            <p>{domain}</p>
        </div>
    );
}

export default App;
